import { BarList } from "@tremor/react"
import { capitalCase } from "change-case"
import { useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { useAsyncEffect } from "../../utils/util.tsx"
import { currencyFormatter, DataTableState, QueryData } from "../common.tsx"
import { StatusFlag } from "./status_state.tsx"
import { LoadingSpinner } from "./summary_cards.tsx"

type DeductionCategoryData = {
  name: string
  value: number
  amount: number
}

export const STATUS_COLORS: { [key: string]: string } = {
  new: "red",
  validated: "green",
  disputable: "yellow",
  review: "yellow",
  backup_requested: "slate",
  backup_received: "orange",
  disputed: "slate",
  processing: "slate",
  won: "green",
  misc: "slate",
}

export const SORT_ORDER = {
  new: 0,
  validated: 1,
  disputable: 2,
  backup_requested: 3,
  backup_received: 4,
  review: 5,
  // don't need to work on it
  disputed: 6,
  processing: 7,
  won: 11,
  misc: 12, // TODO kill this
}

export function StatusBarChart({
  selectedDay,
  selectedDistributor,
}: {
  selectedDay: number
  selectedDistributor: string
}) {
  const [data, setData] = useState<DeductionCategoryData[]>([])
  const [loading, setLoading] = useState(true)
  const statusFlag = StatusFlag.use(sf => sf!)
  const tableState = DataTableState.use()

  useAsyncEffect(async () => {
    const distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    let params = {
      days: selectedDay,
      distributor: distributor,
      query_name: "status",
    }
    let res = await api_fetch<QueryData>("/deductions/query", { params })
    setLoading(false)
    if (!res.ok) {
      setData([])
      throw new Error("Failed to fetch data")
    }

    const headerIndex = res.value.data.headers.reduce((acc, header, index) => {
      acc[header] = index
      return acc
    }, {} as { [key: string]: number })

    let total = res.value.data.rows
      .map(row => row[headerIndex.deduction_count] as number)
      .reduce((a, b) => a + b, 0)
    let nudge = total * 0.3
    let data = res.value.data.rows.map(row => {
      let amount = row[headerIndex.total_dollar_amount] as number
      let count = row[headerIndex.deduction_count] as number
      return {
        key: row[headerIndex.status_value] as keyof typeof SORT_ORDER,
        amount: amount,
        name: `${capitalCase(row[headerIndex.status_value] as string)} (${count})`,
        value: count + nudge,
        color: STATUS_COLORS[row[headerIndex.status_value] as keyof typeof STATUS_COLORS] || "gray",
      }
    })
    data.sort((a, b) => SORT_ORDER[a.key] - SORT_ORDER[b.key])
    setData(data)
  }, [selectedDay, selectedDistributor, statusFlag])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div class="flex">
      <BarList
        data={data}
        className="max-w-2xl grow"
        sortOrder="none"
        valueFormatter={(n: any) => ""}
        onValueChange={(item: any) => {
          if (item?.key.toLowerCase() === "won") {
            DataTableState.set({
              ...tableState,
              search: "",
              columnFilters: item?.key
                ? [
                    ...(tableState.columnFilters || []).filter(f => f.id !== "status_value"),
                    { id: "status_value", value: item.key },
                  ]
                : (tableState.columnFilters || []).filter(f => f.id !== "status_value"),
            })
          } else {
            DataTableState.set({ ...tableState, search: item?.key, columnFilters: [] })
          }
        }}
      />
      <div class="xl:ml-6">
        {data.map((d, i) => {
          return (
            <div class="h-8 mb-1.5 items-center flex">{currencyFormatter(Math.abs(d.amount))}</div>
          )
        })}
      </div>
    </div>
  )
}
