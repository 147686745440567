import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"

import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { useToast } from "@/components/ui/use-toast"
import { useState, useEffect } from "preact/hooks"
import { StatusFlag } from "../../status_state"
import React from "preact/compat"
import { File } from "lucide-react"
import { file_dispute } from "@/api/dispute.tsx"
import { DeductionResponse } from "@/api/deduction.tsx"
import { getFiles } from "../../detail/index"
import { handleViewS3Uri } from "../../detail/files"
import { Trash2, Eye } from "lucide-react"
import { DeductionFile } from "../../detail/files"

const DEFAULT_DISPUTE_MESSAGE =
  "Hi, please see attached documents showing that this is an invalid charge. Thank you in advance and please let me know if you have any questions."

interface FileDisputeDrawerProps {
  deduction: DeductionResponse
  trigger: JSX.Element
}

interface FileWithUpload extends DeductionFile {
  file?: File
  isNew?: boolean
}

export function FileDisputeDrawer({ deduction, trigger }: FileDisputeDrawerProps) {
  let [files, setFiles] = useState<FileWithUpload[]>([])
  let [uploading, setUploading] = useState(false)
  let [disputeMessage, setDisputeMessage] = useState(DEFAULT_DISPUTE_MESSAGE)
  let [disputeAmount, setDisputeAmount] = useState<number>(deduction.invoice_amount)
  const { toast } = useToast()

  // Load existing files when drawer opens
  useEffect(() => {
    const existingFiles = getFiles(deduction, [], [])
    setFiles(
      existingFiles.map(file => ({
        ...file,
        isNew: false,
      }))
    )
  }, [deduction])

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement
    if (!target.files?.length) return

    const newFiles: FileWithUpload[] = Array.from(target.files).map(file => ({
      display_name: file.name,
      date: new Date().toISOString(),
      s3_uri: "",
      file,
      isNew: true,
    }))
    setFiles([...files, ...newFiles])
  }

  function removeFile(indexToRemove: number) {
    setFiles(files.filter((_, index) => index !== indexToRemove))
  }

  function handleDisputeAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
    // @ts-ignore
    setDisputeAmount(event.target!.value)
  }

  async function handleFileDispute() {
    setUploading(true)

    // Separate new files and existing files
    const newFiles = files.filter(f => f.isNew && f.file).map(f => f.file!)
    const existingFileUris: string[] = files.filter(f => !f.isNew).map(f => f.s3_uri)

    let res = await file_dispute({
      deduction,
      message: disputeMessage,
      dispute_amount: disputeAmount,
      files: newFiles,
      existing_file_uris: existingFileUris.join(","),
    })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to file dispute. Please try again.",
        variant: "destructive",
      })
      console.error("Failed to file dispute")
    } else {
      toast({
        title: "Dispute filed",
        description: "Successfully filed dispute. Messages will be synced in a few minutes.",
      })
      console.log("Dispute filed successfully")
    }
    StatusFlag.set(flag => !flag) // Re-render
    setUploading(false)
  }

  let invoice_number = deduction.invoice_number
  let source = deduction.source
  return (
    <Drawer>
      <DrawerTrigger>{trigger}</DrawerTrigger>

      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>File Dispute</DrawerTitle>
          <DrawerDescription>
            Review, edit, and upload relevant files then hit submit to file dispute.
          </DrawerDescription>

          <DrawerTitle className="text-md pt-2">Title</DrawerTitle>
          <DrawerDescription>
            {`Supplier Dispute Form - Invoice #: ${invoice_number}`}
          </DrawerDescription>

          <DrawerTitle className="text-md pt-2">Message</DrawerTitle>
          {/* @ts-ignore */}
          <Textarea
            value={disputeMessage}
            onChange={(e: any) => setDisputeMessage(e.target.value)}
            className="border border-plue-100 hover:border-plue-500"
          />

          {source === "unfi" && (
            <>
              <DrawerTitle>Dispute amount</DrawerTitle>{" "}
              <input
                type="number"
                step="0.01"
                value={disputeAmount}
                onChange={handleDisputeAmountChange}
                className="border border-plue-100 hover:border-plue-500 p-1 rounded w-36 text-right"
              />
            </>
          )}

          <DrawerTitle className="text-md pt-2">Attachments</DrawerTitle>
          <div className="flex flex-col">
            {files.map((file, index) => (
              <div key={index} className="flex items-center justify-between mt-2">
                <div className="flex items-center">
                  <File className="w-4 h-4 mr-1" />
                  <DrawerDescription>{file.display_name}</DrawerDescription>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    onClick={e => {
                      e.preventDefault()
                      if (!file.isNew) {
                        handleViewS3Uri(file.s3_uri)(e as unknown as MouseEvent)
                      } else if (file.file) {
                        const url = URL.createObjectURL(file.file)
                        window.open(url, "_blank")
                        URL.revokeObjectURL(url)
                      }
                    }}>
                    <Eye className="h-4 w-4" />
                  </Button>
                  <Button variant="ghost" onClick={() => removeFile(index)}>
                    <Trash2 className="h-4 w-4 text-red-500" />
                  </Button>
                </div>
              </div>
            ))}

            <form method="post" encType="multipart/form-data">
              <div className="mt-4 mb-2">
                <label for="image_uploads" class="btn btn-secondary">
                  Select files
                </label>
                <input
                  type="file"
                  style="opacity: 0"
                  id="image_uploads"
                  name="image_uploads"
                  multiple
                  onChange={handleFileChange}
                  className="mt-2"
                />
              </div>
            </form>
          </div>
        </DrawerHeader>

        <DrawerFooter className="flex flex-row items-start">
          <DrawerClose>
            <Button onClick={handleFileDispute} disabled={uploading} variant="tertiary">
              {uploading ? "Uploading..." : "Submit"}
            </Button>
          </DrawerClose>
          <DrawerClose>
            <Button variant="outline">Cancel</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
