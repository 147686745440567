import { useEffect, useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { DeductionResponse } from "../../api/deduction.tsx"
import { useAsyncEffect, useSearchParams } from "../../utils/util.tsx"
import { BreadcrumbNav, DataTableState } from "../common.tsx"
import { GenericFilter } from "../component/filter.tsx"
import { StatusFlag } from "./status_state"
import { SummaryCards } from "./summary_cards.tsx"
import { COLUMNS } from "./table/columns.tsx"
import { DataTable } from "./table/data_table.tsx"
import { Button } from "@/components/ui/button"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { Filter } from "lucide-react"

export const DAY_COUNTS = [7, 14, 21, 30, 60, 90, 120, 150, 180, 365, 500]
export const DISTRIBUTORS = ["All Distributors", "KeHE", "UNFI", "Target", "Ahold"]

export function dayCountToString(item: number) {
  if (item > 365) {
    return "All time"
  } else if (item === 365) {
    return "Past year"
  } else if (item < 365 && item > 30) {
    return `Past ${Math.round(item / 30)} months`
  } else {
    return `Past ${item} days`
  }
}

export interface Params {
  search?: string
  days?: number
  distributor?: string
}

export function Deductions() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<DeductionResponse[]>([])
  const statusFlag = StatusFlag.use(sf => sf!)
  const tableState = DataTableState.use(ts => ts!)
  const [isOpen, setIsOpen] = useState(false)

  // Get the initial search value from URL query params
  const { search: urlSearch } = useSearchParams<{ search?: string }>()

  // Use useEffect to set the initial search from URL if it exists
  useEffect(() => {
    if (urlSearch && urlSearch !== tableState.search) {
      DataTableState.set({
        ...tableState,
        search: urlSearch,
      })
    }
  }, [urlSearch])

  const {
    search = "",
    days: selectedDay = DAY_COUNTS[8],
    distributor: selectedDistributor = DISTRIBUTORS[0],
  } = tableState

  function handleDayCountChange(days: number) {
    DataTableState.set({ ...tableState, days })
  }

  function handleDistributorChange(distributor: string) {
    DataTableState.set({ ...tableState, distributor })
  }

  useAsyncEffect(async () => {
    let distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    const res = await api_fetch<Omit<DeductionResponse, "validated">[]>("/deduction", {
      params: { days: selectedDay, distributor },
    })
    setLoading(false)
    if (!res.ok) {
      throw new Error("Failed to fetch data")
    }

    // convert null values to empty strings to ensure filtering works as expected
    const data = res.value.data.map(d => {
      let kv: [string, any][] = Object.entries(d).map(([k, v]) => [k, v ?? ""])
      kv.push(["validated", d.status_value === "validated"])
      return Object.fromEntries(kv) as DeductionResponse
    })
    setData(data)
  }, [selectedDay, statusFlag, selectedDistributor])

  // Update the filterableColumns to only include visible columns
  const filterableColumns = COLUMNS.filter(column => {
    // Exclude action/special columns
    const excludedColumns = ["select", "actions", "files", "task"]
    // @ts-ignore
    const columnKey = column.id || (column.accessorKey as string)
    return (
      !excludedColumns.includes(columnKey) &&
      (!tableState.columnVisibility || tableState.columnVisibility[columnKey] !== false)
    )
  }).map(column => {
    // Extract the title from the header component
    let title = ""
    if (typeof column.header === "function") {
      const headerProps = { column: { id: column.id } }
      const headerResult = column.header(headerProps as any)
      // Access the title prop from the ColumnHeader component
      // @ts-ignore
      title = headerResult?.props?.title || column.id || (column.accessorKey as string)
    } else {
      // @ts-ignore
      title = column.id || (column.accessorKey as string)
    }
    return { ...column, title }
  })

  // Add effect to handle opening collapsible when filters are present
  useEffect(() => {
    const hasFilters = tableState.columnFilters && tableState.columnFilters.length > 0
    if (hasFilters && !isOpen) {
      setIsOpen(true)
    } else if (!hasFilters && isOpen) {
      setIsOpen(false)
    }
  }, [tableState.columnFilters])

  return (
    <div>
      <BreadcrumbNav items={[{ label: "Deductions", href: "/deductions" }]} />

      <GenericFilter
        items={DISTRIBUTORS}
        selectedItem={selectedDistributor}
        handleOnChange={handleDistributorChange}
        itemToString={item => item}
        className="absolute right-44 top-0 mt-6 w-36 mr-8"
      />
      <GenericFilter
        items={DAY_COUNTS}
        selectedItem={selectedDay}
        handleOnChange={handleDayCountChange}
        itemToString={dayCountToString}
        className="absolute right-8 top-0 mt-6 w-40 max-w-sm"
      />
      <SummaryCards selectedDay={selectedDay} selectedDistributor={selectedDistributor} />
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <div className="flex items-center space-x-2">
          <CollapsibleTrigger asChild>
            {/* @ts-ignore */}
            <Button variant="outline" size="sm">
              <Filter className="h-4 w-4 mr-2" />
              Filters
            </Button>
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent className="mt-4">
          <div className="grid grid-cols-5 gap-4">
            {filterableColumns.map(column => {
              // @ts-ignore
              const columnKey = column.id || (column.accessorKey as string)
              return (
                <div key={columnKey} className="space-y-2">
                  <label className="text-sm text-plue-900">{column.title}</label>
                  <input
                    className="w-full rounded-md px-3 py-2"
                    placeholder={
                      // @ts-ignore
                      column.id?.includes("date") || column.accessorKey?.includes("date")
                        ? "MM/DD or MM/DD/YYYY"
                        : ""
                    }
                    value={
                      (tableState.columnFilters?.find(f => f.id === columnKey)?.value as string) ||
                      ""
                    }
                    onChange={(e: Event) => {
                      const target = e.target as HTMLInputElement
                      const value = target.value
                      DataTableState.set({
                        ...tableState,
                        columnFilters: value
                          ? [
                              ...(tableState.columnFilters || []).filter(f => f.id !== columnKey),
                              { id: columnKey, value },
                            ]
                          : (tableState.columnFilters || []).filter(f => f.id !== columnKey),
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>
        </CollapsibleContent>
      </Collapsible>
      <div className="mx-auto">
        <DataTable loading={loading} columns={COLUMNS} data={data} />
      </div>
    </div>
  )
}
