import { DeductionResponse } from "@/api/deduction.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { useToast } from "@/components/ui/use-toast"
import { BreadcrumbNav, DataTableState } from "@/dashboard/common.tsx"
import { GenericFilter } from "@/dashboard/component/filter.tsx"
import { DAY_COUNTS, dayCountToString, DISTRIBUTORS } from "@/dashboard/deductions/index.tsx"
import { CardBody } from "@/dashboard/deductions/summary_cards.tsx"
import { DataTable } from "@/dashboard/deductions/table/data_table.tsx"
import { differenceInDays, startOfMonth, startOfYear, subMonths } from "date-fns"
import { Loader2, WandSparklesIcon } from "lucide-react"
import { useState } from "preact/hooks"
import { entity, persistence } from "simpler-state"
import { api_fetch } from "../api/client.tsx"
import { BreakdownBarChart } from "../dashboard/component/breakdown_bar_chart.tsx"
import { StatusFlag } from "../dashboard/deductions/status_state.tsx"
import { useAsyncEffect } from "../utils/util.tsx"
import { columns } from "./accounting_columns.tsx"
import { DetailedMonthlyExpenseBreakdownChart } from "./detailed_monthly_expense_breakdown.tsx"

interface AccountingStats {
  codedCount: number
  uncodedCount: number
}

// custom filter values
const CODED = "coded"
const UNCODED = "uncoded"
const CURRENT_MONTH = "currentMonth"
const PREVIOUS_MONTH = "previousMonth"
const YEAR_TO_DATE = "yearToDate"

interface AccountingFilters {
  codedFilter: string
  monthFilter: string
  customDays: number | null
}

const initialAccountingState: AccountingFilters = {
  codedFilter: "all",
  monthFilter: "all",
  customDays: null,
}

export const AccountingState = entity(initialAccountingState, [
  persistence("accounting_filters_state"),
])

export function Accounting() {
  const statusFlag = StatusFlag.use(sf => sf!)

  const [loading, setLoading] = useState(true)
  const [autoCodeLoading, setAutoCodeLoading] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [stats, setStats] = useState<AccountingStats>()

  const accountingState = AccountingState.use()

  const tableState = DataTableState.use()

  const { days: selectedDay = DAY_COUNTS[8], distributor: selectedDistributor = DISTRIBUTORS[0] } =
    tableState

  const { toast } = useToast()

  function handleDayCountChange(days: number) {
    DataTableState.set({ ...tableState, days })
    // Reset month filter and custom days when user selects a new day count
    AccountingState.set(state => ({ ...state, monthFilter: "all", customDays: null }))
  }

  function handleDistributorChange(distributor: string) {
    DataTableState.set({ ...tableState, distributor })
  }

  function handleSetCodedFilter(filter: string) {
    if (filter === accountingState.codedFilter) {
      AccountingState.set(state => ({ ...state, codedFilter: "all" }))
    } else {
      AccountingState.set(state => ({ ...state, codedFilter: filter }))
    }
  }

  // TODO(joey): sort reason codes so they look less jarring
  // TODO: add toggle so invoices aren't shown in the accounting table
  function handleSetMonthFilter(filter: string) {
    if (filter === accountingState.monthFilter) {
      AccountingState.set(state => ({
        ...state,
        monthFilter: "all",
        customDays: null,
      }))
    } else {
      const today = new Date()
      let newCustomDays = null

      if (filter === CURRENT_MONTH) {
        newCustomDays = differenceInDays(today, startOfMonth(today))
      } else if (filter === PREVIOUS_MONTH) {
        const previousMonth = subMonths(today, 1)
        newCustomDays = differenceInDays(today, startOfMonth(previousMonth))
      } else if (filter === YEAR_TO_DATE) {
        newCustomDays = differenceInDays(today, startOfYear(today))
      }

      // DataTableState.set({ ...tableState, days: newCustomDays ?? selectedDay })
      AccountingState.set(state => ({
        ...state,
        monthFilter: filter,
        customDays: newCustomDays,
      }))
    }
  }

  async function handleAutoCode() {
    setAutoCodeLoading(true)
    const res = await api_fetch<{ coded_count: number }>("/auto_code_deductions", {
      method: "POST",
      body: [],
    })

    if (!res.ok) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to auto-code deductions",
      })
      setAutoCodeLoading(false)
      return
    }

    toast({
      title: "Auto-Code Complete",
      description: `Successfully coded ${res.value.data?.coded_count} deduction${
        res.value.data?.coded_count === 1 ? "" : "s"
      }`,
    })

    StatusFlag.set(flag => !flag)
    setAutoCodeLoading(false)
  }

  useAsyncEffect(async () => {
    let distributor = selectedDistributor === "All Distributors" ? "" : selectedDistributor
    const res = await api_fetch<Omit<DeductionResponse, "validated">[]>("/deduction", {
      params: {
        days: accountingState.customDays ?? selectedDay,
        distributor,
        query_type: "accounting",
      },
    })

    if (!res.ok) {
      setLoading(false)
      throw new Error("Failed to fetch data")
    }

    setLoading(false)

    const data = res.value.data.map(d => {
      let kv: [string, any][] = Object.entries(d).map(([k, v]) => [k, v ?? ""])
      kv.push(["validated", d.status_value === "validated"])
      return Object.fromEntries(kv) as DeductionResponse
    })

    let filteredData = data

    if (accountingState.monthFilter === CURRENT_MONTH || accountingState.monthFilter === PREVIOUS_MONTH) {
      const now = new Date()
      const targetMonth = accountingState.monthFilter === CURRENT_MONTH 
        ? now.getMonth() 
        : (now.getMonth() - 1 + 12) % 12 // Handle January case

      filteredData = filteredData.filter(d => {
        const rawDate = d.check_date ? d.check_date : d.invoice_date
        const parsedDate = new Date(rawDate)
        const timezoneOffset = parsedDate.getTimezoneOffset() * 60000
        const adjustedDate = new Date(parsedDate.getTime() + timezoneOffset)
        return adjustedDate.getMonth() === targetMonth
      })
    }

    setStats({
      codedCount: filteredData.filter(d => (d.reason_codes ?? []).length > 0).length,
      uncodedCount: filteredData.filter(d => !(d.reason_codes ?? []).length).length,
    })

    if (accountingState.codedFilter === CODED) {
      filteredData = filteredData.filter(d => (d.reason_codes ?? []).length > 0)
    } else if (accountingState.codedFilter === UNCODED) {
      filteredData = filteredData.filter(d => !(d.reason_codes ?? []).length)
    }
    setData(filteredData)
  }, [
    selectedDay,
    statusFlag,
    selectedDistributor,
    accountingState.codedFilter,
    accountingState.monthFilter,
    accountingState.customDays,
  ])

  return (
    <div>
      <BreadcrumbNav items={[{ label: "Accounting", href: "/accounting" }]} />
      <div className="flex gap-4 -mt-10 justify-end mb-4">
        <GenericFilter
          items={DISTRIBUTORS}
          selectedItem={selectedDistributor}
          handleOnChange={handleDistributorChange}
          itemToString={item => item}
          className="w-36"
        />
        <GenericFilter
          items={DAY_COUNTS}
          selectedItem={accountingState.customDays ?? selectedDay}
          handleOnChange={handleDayCountChange}
          itemToString={dayCountToString}
          className="w-40 max-w-sm"
        />
        <div className="flex gap-4 flex-col w-32 justify-end">
          <Button
            variant={accountingState.monthFilter === CURRENT_MONTH ? "tertiary" : "outline"}
            onClick={() => handleSetMonthFilter(CURRENT_MONTH)}>
            Current Month
          </Button>
          <Button
            variant={accountingState.monthFilter === PREVIOUS_MONTH ? "tertiary" : "outline"}
            onClick={() => handleSetMonthFilter(PREVIOUS_MONTH)}>
            Previous Month
          </Button>
          <Button
            variant={accountingState.monthFilter === YEAR_TO_DATE ? "tertiary" : "outline"}
            onClick={() => handleSetMonthFilter(YEAR_TO_DATE)}>
            Year to Date
          </Button>
        </div>
      </div>

      <div className="flex justify-between gap-4 mb-4">
        <div className="flex-grow max-w-xl">
          <Card className="h-full">
            <CardHeader className="flex space-y-0 pb-2">
              <CardTitle className="text-md font-medium">Expense Account Breakdown</CardTitle>
            </CardHeader>
            <CardContent>
              <BreakdownBarChart
                selectedDay={selectedDay}
                selectedDistributor={selectedDistributor}
                queryName="expense_account_buckets"
              />
            </CardContent>
          </Card>
        </div>
        <div className="w-48">
          <ProgressCards
            stats={stats}
            codedFilter={accountingState.codedFilter}
            handleSetCodedFilter={handleSetCodedFilter}
          />
          <div className="flex justify-end">
            <Button
              className="mt-4"
              variant="tertiary"
              onClick={handleAutoCode}
              disabled={autoCodeLoading}>
              {autoCodeLoading ? (
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                <WandSparklesIcon className="w-4 h-4 mr-2" />
              )}
              Auto Code 
            </Button>
          </div>
        </div>
      </div>

      <DataTable loading={loading} columns={columns} data={data} />

      <div className="mt-8">
        <DetailedMonthlyExpenseBreakdownChart />
      </div>
    </div>
  )
}

const ProgressCards = ({
  stats,
  codedFilter,
  handleSetCodedFilter,
}: {
  stats?: AccountingStats
  codedFilter: string
  handleSetCodedFilter: (filter: string) => void
}) =>
  stats ? (
    <div className="flex flex-col gap-4">
      <Card className={`${codedFilter === CODED ? "border-blue-400" : ""}`}>
        <CardBody
          value={stats.codedCount}
          title="Coded"
          color="green-700"
          onClick={() => handleSetCodedFilter(CODED)}
        />
      </Card>
      <Card className={`${codedFilter === UNCODED ? "border-blue-400" : ""}`}>
        <CardBody
          value={stats.uncodedCount}
          title="Uncoded"
          color="red-800"
          onClick={() => handleSetCodedFilter(UNCODED)}
        />
      </Card>
    </div>
  ) : null
