import { DeductionResponse, ReasonCodeInfo } from "@/api/deduction.tsx"
import { formatDistributor, useAsyncEffect } from "@/utils/util.tsx"
import { useEffect, useState } from "preact/compat"

import { Backup } from "@/api/backup.tsx"
import { BreadcrumbNav } from "@/dashboard/common.tsx"
import { DeductionFile } from "@/dashboard/deductions/detail/files.tsx"
import { getFiles } from "@/dashboard/deductions/detail/index.tsx"
import { StatusFlag } from "@/dashboard/deductions/status_state"
import { LoadingSpinner } from "@/dashboard/deductions/summary_cards"
import { api_fetch } from "src/api/client.tsx"
import { Uuid } from "src/utils/type.tsx"

import { FileSelector } from "./FileSelector"
import { PDFViewer } from "./PDFViewer"
import { BackupTableWithMatching, createEmptyBackup } from "./backup_table_with_matching"

import { DeductionCard } from "@/dashboard/deductions/detail/deduction_card"
import Decimal from "decimal.js"

export function AccountingDetail({ params }: { params: { deduction_id: Uuid } }) {
  const [loading, setLoading] = useState(true)
  const [backupLoading, setBackupLoading] = useState(true)
  const [deduction, setDeduction] = useState<DeductionResponse>()
  const [backup, setBackup] = useState<Backup[]>([])
  const [files, setFiles] = useState<DeductionFile[]>([])
  const [selectedFile, setSelectedFile] = useState<DeductionFile | null>(null)
  const statusFlag = StatusFlag.use(sf => sf!)

  // Convert Backup[] to ReasonCodeInfo[]
  const convertBackupToReasonCodeInfo = (backupData: Backup[]): ReasonCodeInfo[] => {
    if (!deduction) return []

    // First, group by customer_name and product_description
    const groupedBackups = backupData.reduce(
      (groups, backup) => {
        // Skip if neither retailer_name nor product_description exists
        if (!backup.retailer_name && !backup.product_description) {
          return groups
        }

        const key = `${backup.retailer_name || ""}__${backup.product_description || ""}`
        if (!groups[key]) {
          groups[key] = {
            items: [],
            total: new Decimal(0),
            retailer_name: backup.retailer_name,
            product_description: backup.product_description || null,
            // Use the first occurrence for these fields
            promo_type: backup.promo_type || null,
            promo_sub_type: backup.promo_sub_type || null,
            source: backup.source,
            customer_name: formatDistributor(deduction.source),
            code_description: "",
            execution_date: backup.execution_date,
          }
        }
        groups[key].items.push(backup)
        groups[key].total = groups[key].total.plus(new Decimal(backup.total || 0))
        return groups
      },
      {} as Record<
        string,
        {
          items: Backup[]
          total: Decimal
          retailer_name: string | null
          product_description: string | null
          promo_type: string | null
          promo_sub_type: string | null
          source: string | null
          customer_name: string
          code_description: string
          execution_date: string | null
        }
      >
    )

    // Convert grouped data to ReasonCodeInfo[]
    const convertedBackups = Object.values(groupedBackups).map((group, index) => ({
      id: `grouped-${index}`,
      name: group.product_description || "Unknown",
      description: group.promo_sub_type || "",
      expense_account: "",
      actor: group.source || "",
      code_type: group.promo_type || "unknown",
      amount: group.total.toNumber(),
      customer_name: group.retailer_name || "",
      product_line: group.product_description || "",
      promo_type: group.promo_type || "",
      promo_sub_type: group.promo_sub_type || "",
      retailer_name: group.retailer_name || "",
      code_description: group.promo_sub_type || "",
      product_category: "", // todo: could we map this from UPC? 
      execution_date: group.execution_date || "",
      retailer_invoice_number: "", // todo: do we parse this?
    }))

    // Calculate total and check if we need an empty backup
    const backupTotal = convertedBackups.reduce(
      (sum, b) => sum.plus(new Decimal(b.amount || 0)),
      new Decimal(0)
    )
    const deductionAmount = new Decimal(Math.abs(deduction.invoice_amount))
    const difference = deductionAmount.minus(backupTotal)

    if (difference.greaterThan(new Decimal("0.001"))) {
      const remainingAmount = difference.toNumber()
      const emptyBackup = createEmptyBackup(deduction, remainingAmount)

      // todo(joey): do we want to just throw out backup if it's not adding to total amount?
      return [...convertedBackups, emptyBackup]
    }

    return convertedBackups
  }

  // Get the data to display - either from reason_codes or converted backup
  const getDisplayData = () => {
    if (deduction?.reason_codes && deduction.reason_codes.length > 0) {
      return deduction.reason_codes
    }
    return convertBackupToReasonCodeInfo(backup)
  }

  useAsyncEffect(async () => {
    const deductionRes = await api_fetch<DeductionResponse>(`/deduction/${params.deduction_id}`)
    const backupRes = await api_fetch<Backup[]>(`/deduction/${params.deduction_id}/backup`)

    if (!deductionRes.ok) {
      setLoading(false)
      throw new Error("Failed to fetch deduction")
    }

    setDeduction(deductionRes.value.data)
    if (backupRes.ok) {
      setBackup(backupRes.value.data)
    }
    setBackupLoading(false)

    setLoading(false)
  }, [statusFlag])

  useEffect(() => {
    if (deduction) {
      setFiles(getFiles(Object.assign({}, deduction), [], []))
    }
  }, [deduction])

  useEffect(() => {
    // Select the backup file by default
    if (files.length > 0) {
      const backupFile = files.find(file => file.display_name.toLowerCase().includes("backup"))
      setSelectedFile(backupFile || files[0])
    }
  }, [files])

  if (!deduction || loading) {
    return <LoadingSpinner color="plue-500" />
  }

  return (
    <div>
      <BreadcrumbNav
        items={[
          { label: "Accounting", href: "/accounting" },
          { href: "#", label: deduction.invoice_number },
        ]}
      />
      <DeductionCard deduction={deduction} onUpdate={setDeduction} />

      <div class="flex flex-col gap-4">
        <div class="w-full">
          <BackupTableWithMatching
            backup={getDisplayData()}
            deduction={deduction}
            loading={backupLoading}
            deduction_id={params.deduction_id}
          />
        </div>

        {selectedFile && (
          <div class="w-full">
            <FileSelector
              files={files}
              selectedFile={selectedFile}
              onFileSelect={setSelectedFile}
            />

            <PDFViewer s3Uri={selectedFile.s3_uri} fileName={selectedFile.display_name} />
          </div>
        )}
      </div>
    </div>
  )
}
