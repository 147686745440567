import { useEffect, useState } from "preact/hooks"
import { addDays, format, parseISO } from "date-fns"
import { api_fetch } from "src/api/client.tsx"
import { QueryData } from "../dashboard/common"
import {
  ChartData,
  MonthlyBreakdownChartBase,
  RawData,
} from "../dashboard/sales/monthly_breakdown_base"

interface DetailedRawData extends RawData {
  expense_account: string
}

const colorMap: { [key: string]: string } = {
  blue: "#3b82f690",
  green: "#22c55e90",
  yellow: "#eab30890",
  red: "#ef444490",
  purple: "#a855f790",
  pink: "#ec489990",
  indigo: "#6366f190",
  teal: "#14b8a690",
  orange: "#f9731690",
  cyan: "#06b6d490",
}

export function DetailedMonthlyExpenseBreakdownChart() {
  const [rawData, setRawData] = useState<DetailedRawData[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])
  const [expenseAccounts, setExpenseAccounts] = useState<string[]>([])

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await api_fetch<QueryData>(`/deductions/query`, {
          params: { query_name: "monthly_expense_account_buckets", distributor: "", days: "365" },
        })

        if (!res.ok) {
          throw new Error("Failed to fetch data")
        }

        const headerIndex = res.value.data.headers.reduce((acc, header, index) => {
          acc[header] = index
          return acc
        }, {} as { [key: string]: number })

        const data = res.value.data.rows.map(row => {
          const parsedDate = parseISO(row[headerIndex.month] as string)
          const correctedDate = addDays(parsedDate, 1) // Adjust for off-by-one error
          return {
            month: format(correctedDate, "MMM yyyy"),
            expense_account: row[headerIndex.expense_account] as string,
            total_amount: Number(row[headerIndex.total_amount]),
          }
        })

        setRawData(data)
        setExpenseAccounts([...new Set(data.map(item => item.expense_account))])
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    function processData() {
      const grouped: { [key: string]: ChartData } = {}

      rawData.forEach(item => {
        if (!grouped[item.month]) {
          grouped[item.month] = { month: item.month }
        }
        grouped[item.month][item.expense_account] = item.total_amount
      })

      setChartData(Object.values(grouped))
    }

    processData()
  }, [rawData])

  const fallbackColors = Object.keys(colorMap)

  const chartConfig = expenseAccounts.reduce((config, account, index) => {
    const colorName = fallbackColors[index % fallbackColors.length]
    const color = colorMap[colorName] || "#64748b90" // Default to a neutral color
    config[account] = {
      label: account,
      color: color,
    }
    return config
  }, {} as any)

  return (
    <MonthlyBreakdownChartBase
      title="Monthly Deductions by Expense Account"
      titleTooltip="Showing total deductions for each expense account over the last 12 months"
      chartData={chartData}
      chartConfig={chartConfig}
      dataKeys={expenseAccounts}
    />
  )
}
