import { captureException } from "@sentry/react"
import { capitalCase } from "change-case"
import { DateTime } from "luxon"
import type { JSX } from "preact"
import { useEffect } from "react"
import { useSearch } from "wouter-preact/use-location"

export function classes(...args: (string | JSX.SignalLike<string | undefined> | undefined)[]) {
  return args.filter(Boolean).join(" ")
}

export function useAsyncEffect(effect: () => Promise<any>, deps: any[] = []) {
  return useEffect(() => safeExec(effect), deps)
}

export function safeExec(action: () => Promise<any>) {
  action().catch(e => {
    captureException(e)
    console.log(e)
  })
}

export function useSearchParams<T extends object>(): T {
  let search_string: string = useSearch()
  let search_params = new URLSearchParams(search_string)
  return Object.fromEntries(search_params.entries()) as T
}

export function relative_time(s: string): string {
  let d = DateTime.fromISO(s)
  if (d.diffNow().as("second") > -60) {
    return "just now"
  }
  return d.toRelative() as string
}

export function formatDistributor(name: string): string {
  if (!name) return ""
  if (name.toLowerCase().includes("kehe")) {
    return "KeHE" 
  } else if (name.toLowerCase().includes("unfi")) {
    return "UNFI"
  } else {
    return capitalCase(name)
  }
}

if (import.meta.vitest) {
describe('formatDistributor', () => {
  it('should return "KeHE" for names including "kehe"', () => {
    expect(formatDistributor('kehe distributors')).toBe('KeHE')
    expect(formatDistributor('KEHE')).toBe('KeHE')
  })

  it('should return "UNFI" for names including "unfi"', () => {
    expect(formatDistributor('unfi wholesale')).toBe('UNFI')
    expect(formatDistributor('UNFI')).toBe('UNFI')
  })

  it('should return capitalized case for other names', () => {
    expect(formatDistributor('other distributor')).toBe('Other Distributor')
  })

  it('should return an empty string for empty input', () => {
    expect(formatDistributor('')).toBe('')
  })
})

describe('classes', () => {
  it('should join non-empty string arguments', () => {
    expect(classes('class1', 'class2', 'class3')).toBe('class1 class2 class3')
  })

  it('should filter out falsy values', () => {
    // @ts-ignore
    expect(classes('class1', undefined, 'class2', null, 'class3', false, '')).toBe('class1 class2 class3')
  })

  it('should return an empty string when all arguments are falsy', () => {
    // @ts-ignore
    expect(classes(undefined, null, false, '')).toBe('')
  })
})
}
