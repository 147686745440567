import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { ChartContainer, ChartTooltipContent } from "@/components/ui/chart"
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts"
import { currencyFormatter, TitleArea } from "../common"

export interface RawData {
  month: string
  [key: string]: string | number
}

export interface ChartData {
  month: string
  [key: string]: string | number
}

interface MonthlyBreakdownChartBaseProps {
  title: string
  titleTooltip: string
  chartData: ChartData[]
  chartConfig: any
  dataKeys: string[]
}

export function MonthlyBreakdownChartBase({
  title,
  titleTooltip,
  chartData,
  chartConfig,
  dataKeys,
}: MonthlyBreakdownChartBaseProps) {
  return (
    <div className="flex mt-4">
      <Card className="w-full max-w-4xl">
        <CardHeader>
          <TitleArea title={title} titleTooltip={titleTooltip} />
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart width={600} height={300} data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="month"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value: any) => value.slice(0, 3)}
              />
              <YAxis tickFormatter={(value: any) => currencyFormatter(value)} />
              <Tooltip
                // @ts-ignore
                content={<ChartTooltipContent hideLabel={true} />}
              />
              {dataKeys.map((key, index) => (
                <Bar
                  key={key}
                  dataKey={key}
                  stackId="a"
                  fill={chartConfig[key].color}
                  radius={[4, 4, 0, 0]}
                  name={chartConfig[key].label}
                />
              ))}
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  )
}
