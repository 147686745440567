import { ReasonCode } from "@/api/deduction"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { StatusFlag } from "@/dashboard/deductions/status_state"
import { ColumnHeader } from "@/dashboard/deductions/table/column_header"
import { selectColumn } from "@/dashboard/deductions/table/columns"
import { Column, ColumnDef } from "@tanstack/react-table"
import { Trash2 } from "lucide-react"
import { useEffect, useState } from "react"
import { AccountingMatchPopover } from "../match_popover"
import { BackupWithMatching } from "./backup_table_with_matching"

interface EditableCellProps {
  getValue: () => any
  row: any
  column: Column<BackupWithMatching, unknown>
  table: any
  minWidth?: number
}

const EditableCell = ({ getValue, row, column, table, minWidth = 150 }: EditableCellProps) => {
  const initialValue = getValue()
  return (
    <Input
      className={`h-8 w-full min-w-[${minWidth}px]`}
      value={initialValue as string}
      onChange={(e: Event) => {
        const target = e.target as HTMLInputElement
        table?.options?.meta?.updateData(row.index, column.id, target.value)
      }}
    />
  )
}

const handleSelectMatch = async (code: ReasonCode, row: any, table: any) => {
  if (table.options.meta?.updateData) {
    table.options.meta.updateData(row.index, "reason_code", code)
    table.options.meta.updateData(row.index, "expense_account", code.expense_account)
    table.options.meta.updateData(row.index, "name", code.name)
  }

  StatusFlag.set(flag => !flag)
}

const deleteColumn: ColumnDef<BackupWithMatching, unknown> = {
  id: "delete",
  size: 40,
  enableHiding: false,
  enableSorting: false,
  header: ({ column }) => <ColumnHeader column={column} title="" />,
  cell: ({ row, table }) => (
    <Button
      variant="ghost"
      // @ts-ignore
      size="icon"
      className="h-8 w-8 p-0 text-slate-500 hover:text-red-500"
      onClick={e => {
        e.stopPropagation()
        // @ts-ignore
        table.options.meta?.onDeleteRows?.([row.original.id])
      }}>
      <Trash2 className="h-4 w-4" />
    </Button>
  ),
}

// Helper function to safely parse and format numbers
const formatAmount = (value: number | string): string => {
  // Handle empty or invalid input
  if (value === "" || value === null || value === undefined) {
    return "0.00"
  }

  // Convert to number and handle NaN
  const num = typeof value === "string" ? parseFloat(value) : value
  if (isNaN(num)) return "0.00"

  // Format with exactly 2 decimal places
  return num.toFixed(2)
}

// Helper function to safely parse input value to number
const parseAmount = (value: string): number => {
  // Remove any non-numeric characters except decimal point
  const cleaned = value.replace(/[^\d.-]/g, "")
  const parsed = parseFloat(cleaned)
  return isNaN(parsed) ? 0 : parsed
}

export const backup_accounting_columns: ColumnDef<BackupWithMatching, unknown>[] = [
  selectColumn as ColumnDef<BackupWithMatching, unknown>,
  {
    accessorKey: "customer_name",
    header: ({ column }) => <ColumnHeader column={column} title="Customer" />,
    cell: props => EditableCell({ ...props }),
  },
  {
    accessorKey: "retailer_name",
    header: ({ column }) => <ColumnHeader column={column} title="Retailer" />,
    cell: EditableCell,
    size: 150,
  },
  {
    accessorKey: "product_category",
    header: ({ column }) => <ColumnHeader column={column} title="Product Category" />,
    cell: EditableCell,
    size: 150,
  },
  {
    accessorKey: "product_line",
    header: ({ column }) => <ColumnHeader column={column} title="Product" />,
    cell: props => EditableCell({ ...props, minWidth: 300 }),
  },
  {
    accessorKey: "amount",
    header: ({ column }) => <ColumnHeader column={column} title="Amount" />,
    cell: ({ getValue, row, column, table }) => {
      const initialValue = getValue()
      const [localValue, setLocalValue] = useState(formatAmount(initialValue as number))

      // Update local value when table data changes
      useEffect(() => {
        setLocalValue(formatAmount(initialValue as number))
      }, [initialValue])

      const handleChange = (e: Event) => {
        const target = e.target as HTMLInputElement
        const newValue = target.value

        // Allow empty input while typing
        if (newValue === "") {
          setLocalValue("")
          return
        }

        // Regex to match numbers with up to 2 decimal places
        const isValidInput = /^\d*\.?\d{0,2}$/.test(newValue)
        if (!isValidInput) {
          return
        }

        setLocalValue(newValue)
      }

      const handleBlur = (e: Event) => {
        const target = e.target as HTMLInputElement
        const parsedValue = parseAmount(target.value)
        const formattedValue = formatAmount(parsedValue)

        setLocalValue(formattedValue)
        // @ts-ignore
        table.options.meta?.updateData(row.index, column.id, parsedValue)
      }

      return (
        <Input
          type="text" // Changed to text for better control
          inputMode="decimal" // Better for mobile keyboards
          className="h-8 w-full text-right"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="0.00"
        />
      )
    },
    size: 50,
  },
  {
    id: "expense_account",
    header: ({ column }) => <ColumnHeader column={column} title="Reason Code" />,
    cell: ({ row, table }) => {
      if (!!row.original.expense_account) {
        return (
          <Badge variant="outline" className="bg-plue-50 min-w-[240px]">
            {`${row.original.expense_account}`}
          </Badge>
        )
      }

      return (
        <div className="w-full rounded-md bg-plue-900 max-w-[165px]">
          <AccountingMatchPopover onSelectMatch={code => handleSelectMatch(code, row, table)} />
        </div>
      )
    },
    size: 250,
  },
  {
    accessorKey: "code_description",
    header: ({ column }) => <ColumnHeader column={column} title="Description" />,
    cell: EditableCell,
    size: 150,
  },
  {
    accessorKey: "promo_type",
    header: ({ column }) => <ColumnHeader column={column} title="Promo Type" />,
    cell: EditableCell,
    size: 150,
  },
  {
    accessorKey: "promo_sub_type",
    header: ({ column }) => <ColumnHeader column={column} title="Promo Sub Type" />,
    cell: EditableCell,
    size: 150,
  },
  {
    accessorKey: "execution_date",
    header: ({ column }) => <ColumnHeader column={column} title="Execution Date" />,
    cell: ({ getValue, row, column, table }) => {
      const value = getValue() as string
      return (
        <Input
          type="date"
          className="h-8 w-full"
          value={value ? new Date(value).toISOString().split('T')[0] : ''}
          onChange={(e: Event) => {
            const target = e.target as HTMLInputElement
            // @ts-ignore
            table.options.meta?.updateData(row.index, column.id, target.value)
          }}
        />
      )
    },
    size: 150,
  },
  {
    accessorKey: "retailer_invoice_number",
    header: ({ column }) => <ColumnHeader column={column} title="Retailer Invoice #" />,
    cell: EditableCell,
    size: 125,
  },
  deleteColumn,
]
