import { api_fetch } from "@/api/client"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Form, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useAsyncEffect } from "@/utils/util"
import { zodResolver } from "@hookform/resolvers/zod"
import { Check, ChevronsUpDown } from "lucide-react"
import { useEffect, useState } from "preact/hooks"
import { useForm } from "react-hook-form"
import * as z from "zod"

export const CATEGORY_FORM_SCHEMA = z.object({
  category: z.string().min(1, { message: "Please select a category" }),
  description: z.string().min(1, { message: "Please select a description" }),
  custom_description: z.string().optional(),
})

interface CategoryFormProps {
  onSubmit: (values: any) => void
  deductionId: string
  initialValue?: string
}

const DESCRIPTIONS = ["MCB", "Short shipment", "Price support - on shelf", "Placement", "Free fill"]

export function CategoryForm({ onSubmit, deductionId, initialValue }: CategoryFormProps) {
  const form = useForm<z.infer<typeof CATEGORY_FORM_SCHEMA>>({
    resolver: zodResolver(CATEGORY_FORM_SCHEMA),
    defaultValues: {
      category: initialValue,
      description: "",
      custom_description: "",
    },
  })

  const [descriptionMap, setDescriptionMap] = useState<Record<string, string[]>>({})
  const [categories, setCategories] = useState<string[]>([])
  const [descriptionOpen, setDescriptionOpen] = useState(false)
  const [selectedDescription, setSelectedDescription] = useState<string>("")
  const [categoryOpen, setCategoryOpen] = useState(false)

  const handleSelectDescription = (description: string) => {
    setSelectedDescription(description)
    form.setValue("description", description)
    setDescriptionOpen(false)
  }

  const handleSelectCategory = (category: string) => {
    form.setValue("category", category)
    setCategoryOpen(false)

    // reset description so that we only allow selecting a category - description pair
    form.setValue("description", "")
    setSelectedDescription("")
  }

  function handleSubmit(values: z.infer<typeof CATEGORY_FORM_SCHEMA>) {
    onSubmit(values)
  }

  useEffect(() => {
    if (categoryOpen && descriptionOpen) {
      setDescriptionOpen(false)
    }
  }, [categoryOpen, descriptionOpen])

  useAsyncEffect(async () => {
    const res = await api_fetch<Record<string, string[]>>(
      `/deduction/${deductionId}/category_descriptions`
    )

    if (res.ok) {
      let data = res.value.data
      setCategories(Object.keys(data).filter(category => category !== ""))
      setDescriptionMap(data)
    }
  }, [])

  const selectedCategory = form.watch("category")
  // @ts-ignore
  const descriptions =
    selectedCategory in descriptionMap
      ? ["Other", ...descriptionMap[selectedCategory]]
      : DESCRIPTIONS

  return (
    <Form {...form}>
      <form
        onSubmit={e => {
          form.handleSubmit(handleSubmit)(e)
        }}
        className="space-y-6 w-full max-w-md mx-auto">
        {/* Category Combobox */}
        <FormField
          control={form.control}
          name="category"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Category</FormLabel>
              <div className="relative">
                <Button
                  type="button"
                  variant="outline"
                  role="combobox"
                  aria-expanded={categoryOpen}
                  className="w-full justify-between"
                  onClick={() => setCategoryOpen(!categoryOpen)}>
                  {field.value || "Select a category..."}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
                {categoryOpen && (
                  <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-md shadow-md z-10">
                    <Command>
                      <CommandInput placeholder="Search category..." />
                      <CommandList>
                        <CommandEmpty>No categories found.</CommandEmpty>
                        <CommandGroup>
                          {categories.map((category, idx) => (
                            <CommandItem key={idx} onSelect={() => handleSelectCategory(category)}>
                              <Check
                                className={`mr-2 h-4 w-4 ${
                                  field.value === category ? "opacity-100" : "opacity-0"
                                }`}
                              />
                              {category}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </div>
                )}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        {selectedCategory && (
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <div className="relative">
                  <Button
                    type="button"
                    variant="outline"
                    role="combobox"
                    aria-expanded={descriptionOpen}
                    className="w-full justify-between"
                    onClick={() => setDescriptionOpen(!descriptionOpen)}>
                    {selectedDescription || "Select description..."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                  {descriptionOpen && (
                    <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-md shadow-md z-10">
                      <Command>
                        <CommandInput placeholder="Search description..." />
                        <CommandList>
                          <CommandEmpty>No descriptions found.</CommandEmpty>
                          <CommandGroup>
                            {descriptions?.map((description: string, idx: number) => (
                              <CommandItem
                                key={idx}
                                onSelect={() => handleSelectDescription(description)}>
                                <Check
                                  className={`mr-2 h-4 w-4 ${
                                    selectedDescription === description
                                      ? "opacity-100"
                                      : "opacity-0"
                                  }`}
                                />
                                {description}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </div>
                  )}
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {selectedDescription?.toLowerCase() === "other" && (
          <FormField
            control={form.control}
            name="custom_description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Custom description (optional)</FormLabel>
                <Input
                  {...field}
                  // @ts-ignore
                  type="text"
                  className="input"
                  placeholder="Enter a custom description"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <Button type="submit" variant="tertiary" className="w-full">
          Update
        </Button>
      </form>
    </Form>
  )
}
