import { AccountingState } from "@/accounting/index.tsx"
import { BarList } from "@tremor/react"
import { useState } from "preact/compat"
import { api_fetch } from "../../api/client.tsx"
import { useAsyncEffect } from "../../utils/util.tsx"
import { currencyFormatter, DataTableState, QueryData } from "../common.tsx"
import { StatusFlag } from "../deductions/status_state.tsx"
import { LoadingSpinner } from "../deductions/summary_cards.tsx"
import { CATEGORY_COLORS } from "../deductions/table/columns.tsx"
import { useLocation } from "wouter-preact"

interface BreakdownData {
  name: string
  value: number
  color: string
}

interface BreakdownBarChartProps {
  selectedDay: number
  selectedDistributor: string
  queryName: string
}

const EXPENSE_ACCOUNT_COLORS = {
  "Uncoded": "red",
  "Sales Discounts": "blue",
  "Advertising": "green",
  "Freight": "orange",
  "Spoilage": "red",
  "Slotting": "purple",
  "Other": "gray",
  // Add more expense account colors as needed
}

const BREAKDOWN_COLORS = {
  category: CATEGORY_COLORS,
  expense_account_buckets: EXPENSE_ACCOUNT_COLORS,
}

const FALLBACK_COLORS = [
  "blue",
  "green",
  "orange",
  "red",
  "purple",
  "pink",
  "indigo",
  "cyan",
  "teal",
  "yellow",
]

export function BreakdownBarChart({
  selectedDay,
  selectedDistributor,
  queryName,
}: BreakdownBarChartProps) {
  const [data, setData] = useState<BreakdownData[]>([])
  const [loading, setLoading] = useState(true)
  const tableState = DataTableState.use()
  const accountingState = AccountingState.use()
  const statusFlag = StatusFlag.use(sf => sf!)

  useAsyncEffect(async () => {
    const isAccounting = window.location.pathname.includes("accounting")
    if (isAccounting && accountingState.codedFilter === "uncoded") {
      setLoading(false)
      setData([])
      return
    }

    let params = {
      days: isAccounting ? accountingState.customDays ?? selectedDay : selectedDay,
      distributor: selectedDistributor === "All Distributors" ? "" : selectedDistributor,
      query_name: queryName,
    }
    let res = await api_fetch<QueryData>("/deductions/query", { params })
    if (!res.ok) {
      setData([])
      setLoading(false)
      throw new Error("Failed to fetch data")
    }
    setLoading(false)

    let headers = res.value.data.headers
    let headerIndex = Object.fromEntries(headers.map((header, index) => [header, index]))

    let rows = res.value.data.rows.filter(row => row[headerIndex.name] !== "Total")

    rows.sort((a, b) => (b[headerIndex.value] as number) - (a[headerIndex.value] as number))

    const colorMap = BREAKDOWN_COLORS[queryName as keyof typeof BREAKDOWN_COLORS] || {}

    setData(
      rows.map((row, index) => {
        const name = row[headerIndex.name] as string
        return {
          name,
          value: Math.abs(row[headerIndex.value] as number) as number,
          color:
            colorMap[name as keyof typeof colorMap] ||
            FALLBACK_COLORS[index % FALLBACK_COLORS.length],
        }
      })
    )
  }, [
    selectedDay,
    selectedDistributor,
    statusFlag,
    queryName,
    accountingState.codedFilter,
    accountingState.monthFilter,
    accountingState.customDays,
  ])

  if (loading || !data) {
    return <LoadingSpinner color="blue" />
  }

  return (
    <div className="w-full">
      <BarList
        data={data}
        className="mt-2"
        valueFormatter={currencyFormatter}
        onValueChange={(item: any) => {
          DataTableState.set({ ...tableState, search: item?.name, columnFilters: [] })
        }}
      />
    </div>
  )
}
