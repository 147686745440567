import { useState, useEffect } from "preact/compat"

type PDFViewerProps = {
  s3Uri: string
  fileName: string
}

export function PDFViewer({ s3Uri, fileName }: PDFViewerProps) {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  useEffect(() => {
    const url = `/api/_s3/${encodeURIComponent(s3Uri)}`
    setPdfUrl(url)
  }, [s3Uri])

  if (!pdfUrl) {
    return <div>Loading PDF...</div>
  }

  return (
    <div className="h-full">
      <h3 className="text-lg font-semibold mb-2">{fileName}</h3>
      <iframe
        src={`${pdfUrl}#toolbar=0`}
        className="w-full h-[calc(100vh-200px)]"
        title={fileName}
      />
    </div>
  )
}
