import { DeductionNote, DeductionResponse, DisputeMessage, EmailMessage } from "@/api/deduction.tsx"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Textarea } from "@/components/ui/textarea"
import { ToastViewport } from "@/components/ui/toast"
import { useToast } from "@/components/ui/use-toast"
import { ToastProvider } from "@radix-ui/react-toast"
import { format } from "date-fns"
import { ArrowUp, ChevronDown, ChevronUp, Dot, Mail, Mails } from "lucide-react"
import { useEffect, useRef, useState } from "preact/hooks"
import { api_fetch } from "src/api/client.tsx"
import { UserState } from "src/auth/user"
import { StatusFlag } from "../status_state"
import { handleSaveNote } from "../table/actions/notes"

export function Conversations({
                                deduction,
                                emails,
                                messages,
                              }: {
  deduction: DeductionResponse
  emails?: EmailMessage[]
  messages?: DisputeMessage[]
}) {
  const hasExternalCommunication =
    (emails && emails.length > 0) || (messages && messages.length > 0)
  const defaultTab = hasExternalCommunication ? "external" : "internal"

  return (
    <Card className="flex flex-col h-full overflow-auto">
      <div class="flex justify-between items-center m-4">
        <h2 class="flex text-xl">
          <Mails class="h-6 w-6" />
          <span class="ml-2">Conversations</span>
        </h2>
      </div>
      <Tabs
        defaultValue={defaultTab}
        // @ts-ignore
        className="h-full">
        <TabsList className="mx-4">
          <TabsTrigger value="internal">Internal</TabsTrigger>
          {hasExternalCommunication && <TabsTrigger value="external">External</TabsTrigger>}
        </TabsList>
        <InternalMessages notes={deduction.notes} deduction_id={deduction.id} />
        {hasExternalCommunication && <ExternalMessages emails={emails} messages={messages} />}
      </Tabs>
    </Card>
  )
}

function formatEmailFrom(from: string, sequence: number) {
  // TODO why would from be null / empty string??
  if (!from) return sequence === 0 ? "Your team" : ""
  const re = /<([^>]+)>/
  const match = from.match(re)
  return match ? match[1] : from
}

async function handleSendEmail({
                                 email_remote_id,
                                 reply_message,
                                 setEmail,
                                 toast,
                               }: {
  email_remote_id: string
  reply_message: string
  setEmail: any
  toast: any
}) {
  if (!reply_message) {
    return
  }
  const res = await api_fetch(`/email_message`, {
    method: "POST",
    body: {
      email_remote_id,
      reply_message,
    },
  })

  if (res.ok) {
    // todo: eventually poll on the job status and update the UI once the email is sent
    setEmail("")
    StatusFlag.set(flag => !flag) // hack for now to force a re-render

    toast({
      title: "Email Sent",
      description: "Please wait a bit and refresh this page to see it.",
    })
  } else {
    console.error("Failed to save note")
    toast({
      variant: "destructive",
      title: "Uh oh! Something went wrong.",
      description: "We will look into this and get back to you.",
    })
  }
}

function ExternalMessages({
                            emails,
                            messages,
                          }: {
  emails?: EmailMessage[]
  messages?: DisputeMessage[]
}) {
  const [expandedMessage, setExpandedMessage] = useState<number | null>(null)
  const [email, setEmail] = useState("")
  const { toast } = useToast()
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  function toggleExpand(sequence: number) {
    setExpandedMessage(expandedMessage === sequence ? null : sequence)
  }

  // Function to scroll the container to the bottom
  function scrollToBottom() {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }
  }

  // Scroll to bottom whenever emails or messages change
  useEffect(() => {
    scrollToBottom()
  }, [emails, messages])

  function renderItem({
                        key,
                        from,
                        date,
                        subject,
                        body,
                        sequence,
                        remoteId,
                        isEmail,
                      }: {
    key: string
    from: string
    date: string
    subject?: string
    body: string
    sequence: number
    remoteId?: string
    isEmail: boolean
  }) {
    const formattedDate = format(new Date(date), "MMM d, yyyy")
    const isExpanded = expandedMessage === sequence

    return (
      <>
        <div key={key} className="flex mt-2 gap-2 items-start ml-2">
          <div className="flex flex-col items-center">
            <div className="inline-flex items-center justify-center border p-2 rounded-3xl">
              <Mail className="text-gray-500 h-5 w-5" />
            </div>
          </div>
          <div className="flex flex-col flex-grow overflow-hidden">
            <div className="flex justify-between w-full">
              <div className="text-md truncate">
                {from?.toLowerCase().includes("unfi") ? "UNFI" : from}
              </div>
              <div className="text-gray-500 text-sm mt-1">{formattedDate}</div>
            </div>
            {subject && sequence === 0 && <div className="text-sm mt-1 truncate">{subject}</div>}
            <div className="flex items-center">
              <div
                className={`text-sm mt-1 flex-grow text-gray-500 max-w-full overflow-hidden ${
                  isExpanded ? "" : "line-clamp-2"
                }`}>
                {body}
              </div>
              <div className="mr-2">
                {isExpanded ? (
                  <ChevronUp className="cursor-pointer" onClick={() => toggleExpand(sequence)} />
                ) : (
                  <ChevronDown className="cursor-pointer" onClick={() => toggleExpand(sequence)} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 w-full flex justify-center">
          <Separator />
        </div>
      </>
    )
  }

  if (!emails && !messages) {
    return null
  }

  return (
    <ToastProvider>
      <TabsContent value="external" className="h-full">
        <div
          className="flex flex-col gap-4 mx-4 mt-6 mb-4 overflow-auto h-96"
          ref={messagesContainerRef} // Reference to the scrollable container
        >
          {emails?.map(email =>
            renderItem({
              key: email.id,
              from: formatEmailFrom(email.from, email.sequence),
              date: email.date,
              subject: email.subject,
              body: email.body,
              sequence: email.sequence,
              remoteId: email.remote_id,
              isEmail: true,
            }),
          )}
          {messages
            ?.sort((a, b) => new Date(a.sent_at).getTime() - new Date(b.sent_at).getTime())
            .map(message =>
              renderItem({
                key: message.id,
                from: message.from,
                date: message.sent_at,
                body: message.body,
                sequence: message.sequence,
                remoteId: message.remote_id,
                isEmail: false,
              }),
            )}
        </div>

        {emails && emails.length > 0 ? (
          <div className="justify-end">
            <div className="mt-4">
              <Textarea
                // @ts-ignore
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={(e: any) => {
                  if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
                    e.preventDefault()
                    handleSendEmail({
                      email_remote_id: emails[0].remote_id,
                      reply_message: email,
                      setEmail,
                      toast,
                    })
                  }
                }}
                className="rounded-none border-b-0 border-r-0 border-l-0 focus-visible:border-plue-300 resize-none w-full px-4 py-0 pt-4 pb-0 text-md focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-2"
                placeholder="Send email reply..."
              />
            </div>
            <div className="flex justify-end mb-4">
              <Button
                onClick={() => {
                  handleSendEmail({
                    email_remote_id: emails[0].remote_id,
                    reply_message: email,
                    setEmail,
                    toast,
                  })
                }}
                variant={"secondary"}
                disabled={email.length === 0}
                className="mx-4">
                Send
                <ArrowUp className="w-4 h-4 ml-1" />
              </Button>
            </div>
          </div>
        ) : (
          messages &&
          messages.length > 0 && (
            <div className="flex justify-center w-full m-4 rounded-md">
              <a
                href={`https://kehecx.freshdesk.com/support/tickets/${messages[0].remote_id}`}
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary px-4 py-3">
                Reply in Freshdesk
              </a>
            </div>
          )
        )}
      </TabsContent>
      <ToastViewport />
    </ToastProvider>
  )
}

function InternalMessages({
                            notes,
                            deduction_id,
                          }: {
  notes?: DeductionNote[]
  deduction_id: string
}) {
  if (!notes) {
    return null
  }
  const user = UserState.use()
  const [note, setNote] = useState("")

  return (
    <TabsContent value="internal" className="h-full ">
      <div className="flex flex-col gap-4 mt-6 mx-4 flex-grow overflow-auto h-96">
        {notes.map(({ message, user_id, actor_name, created_at }) => {
          const isSelfNote = user_id === user?.id
          const formattedDate = format(new Date(created_at), "MMM d, yyyy")
          return (
            <div className={`flex flex-col ${isSelfNote ? "items-end" : "items-start"}`}>
              <div
                className={`border border-gray-300 p-2 ${
                  isSelfNote ? "rounded-br-sm" : "rounded-bl-sm"
                } rounded-xl`}>
                <p className="text-gray-500">{message}</p>
              </div>

              <p className="text-sm flex items-center mt-1">
                <span>{isSelfNote ? formattedDate : actor_name}</span>
                <Dot className="w-2 h-2 mx-1" />
                <span>{isSelfNote ? actor_name : formattedDate}</span>
              </p>
            </div>
          )
        })}
      </div>

      <div className="justify-end">
        <div className="mt-4">
          <Textarea
            // @ts-ignore
            value={note}
            onChange={(e: any) => setNote(e.target.value)}
            onKeyDown={(e: any) => {
              if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
                e.preventDefault()
                handleSaveNote({ deduction_id, note, setNote })
              }
            }}
            className="rounded-none border-b-0 border-r-0 border-l-0 resize-none w-full px-4 py-0 pt-4 pb-0 text-md focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-2"
            placeholder="Send an internal note..."
          />
        </div>
        <div className="flex justify-end mb-4">
          <Button
            variant={"secondary"}
            className="mx-4"
            onClick={() => handleSaveNote({ deduction_id, note, setNote })}>
            Send
            <ArrowUp className="w-4 h-4 ml-1" />
          </Button>
        </div>
      </div>
    </TabsContent>
  )
}

if (import.meta.vitest) {
  it("should return \"Your team\" for sequence 0 and empty from", () => {
    expect(formatEmailFrom("", 0)).toBe("Your team")
  })

  it("should return an empty string for non-zero sequence and empty from", () => {
    expect(formatEmailFrom("", 1)).toBe("")
  })

  it("should extract email from angle brackets", () => {
    expect(formatEmailFrom("John Doe <john@example.com>", 1)).toBe("john@example.com")
  })

  it("should return the full string if no angle brackets are present", () => {
    expect(formatEmailFrom("john@example.com", 1)).toBe("john@example.com")
  })
}
