import { DeductionFile } from "@/dashboard/deductions/detail/files"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

type FileSelectorProps = {
  files: DeductionFile[]
  selectedFile: DeductionFile | null
  onFileSelect: (file: DeductionFile) => void
}

export function FileSelector({ files, selectedFile, onFileSelect }: FileSelectorProps) {
  return (
    <div className="flex items-center gap-4 w-1/3 pt-4">
      <label htmlFor="file-select" className="min-w-24">
        Select File:
      </label>
      <Select
        value={selectedFile?.s3_uri || ""}
        onValueChange={value => {
          const selected = files.find(file => file.s3_uri === value)
          if (selected) onFileSelect(selected)
        }}>
        <SelectTrigger id="file-select">
          <SelectValue placeholder="Select a file" />
        </SelectTrigger>
        <SelectContent>
          {files.map(file => (
            <SelectItem key={file.s3_uri} value={file.s3_uri}>
              {file.display_name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
